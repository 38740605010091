import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import { FaPhoneAlt } from "react-icons/fa"
import styled from "styled-components"

import { Layout, Seo } from "../components"

export const query = graphql`
  query GetProfile($slug: String) {
    allDatoCmsAttorney(
      filter: { title: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      nodes {
        attorney {
          ... on DatoCmsTitle {
            id
            slug
            title
          }
          ... on DatoCmsTel {
            id
            tel
            ext
          }
        }
        education
        id
        imageProfile {
          alt
          gatsbyImageData(backgroundColor: "grey")
          url
        }
        info
        seo {
          description
          image {
            author
            gatsbyImageData
            fluid {
              base64
              tracedSVG
              width
              height
            }
            id
          }
          title
          twitterCard
        }
        imageProfileCollection {
          alt
          gatsbyImageData(backgroundColor: "grey")
        }
      }
    }
    datoCmsImage(image: { alt: { eq: "Am House St Alban Vermont" } }) {
      image {
        alt
        gatsbyImageData
      }
    }
  }
`

const ProfileTemplate = ({ data }) => {
  const { datoCmsImage: image } = data
  const attorneyProfiles = data.allDatoCmsAttorney.nodes
  const seoImage = image.image.gatsbyImageData.images.fallback.src

  return (
    <Layout>
      {attorneyProfiles.map(attorneyProfile => {
        const { title, description } = attorneyProfile.seo

        return <Seo title={title} description={description} image={seoImage} />
      })}

      <div className="page-header">
        <Container>
          <SectionHeading className="section">
            <Row>
              {attorneyProfiles.map(attorneyProfile => {
                {
                  /* const image = getImage(
                  attorneyProfile.imageProfile.gatsbyImageData
                ) */
                }

                const title = attorneyProfile.attorney[0].title
                const tel = attorneyProfile.attorney[1].tel
                  .toString()
                  .replace(/(\d{3})(\d{3})(\d{4})/, "$1.$2.$3")

                const ext = attorneyProfile.attorney[1].ext

                return (
                  <>
                    {/* <Col md={6}>
                      <article>
                        <figure>
                          <GatsbyImage
                            image={image}
                            alt={attorneyProfile.imageProfile.alt}
                          />
                        </figure>
                      </article>
                    </Col> */}

                    <Col>
                      <Article>
                        <h2>{title}</h2>
                        <p>
                          <FaPhoneAlt /> {tel} ext: <span>{ext}</span>
                        </p>
                        {/* <Row>
                          {attorneyProfile.imageProfileCollection
                            ? attorneyProfile.imageProfileCollection.map(
                                item => (
                                  <Col md={6}>
                                    <ProfileImageCollection>
                                      <figure>
                                        <GatsbyImage
                                          image={item.gatsbyImageData}
                                          alt={item.alt}
                                        />
                                      </figure>
                                    </ProfileImageCollection>
                                  </Col>
                                )
                              )
                            : null}
                        </Row> */}
                      </Article>
                    </Col>
                  </>
                )
              })}
            </Row>
          </SectionHeading>
        </Container>
      </div>

      <Container>
        <section className="section">
          <Row>
            {attorneyProfiles.map(attorneyProfile => {
              const image = getImage(
                attorneyProfile.imageProfile.gatsbyImageData
              )

              return (
                <>
                  <Col md={6}>
                    <article>
                      <figure>
                        <GatsbyImage
                          image={image}
                          alt={attorneyProfile.imageProfile.alt}
                        />
                      </figure>
                    </article>

                    <article>
                      {attorneyProfiles.map(attorneyProfile => {
                        const education = attorneyProfile.education
                        return (
                          <div
                            className="template__text-body"
                            dangerouslySetInnerHTML={{
                              __html: education,
                            }}
                          ></div>
                        )
                      })}

                      <ProfileImageCollection>
                        {attorneyProfiles.map(attorneyProfile => {
                          const imageCollection =
                            attorneyProfile.imageProfileCollection
                          return imageCollection.map(item => {
                            return (
                              <figure>
                                <GatsbyImage
                                  image={item.gatsbyImageData}
                                  alt={item.alt}
                                />
                              </figure>
                            )
                          })
                        })}
                      </ProfileImageCollection>
                    </article>
                  </Col>

                  <Col md={6}>
                    <ProfileInfo>
                      {attorneyProfiles.map(attorneyProfile => {
                        const info = attorneyProfile.info
                        return (
                          <div
                            className="template__text-body"
                            dangerouslySetInnerHTML={{
                              __html: info,
                            }}
                          ></div>
                        )
                      })}
                    </ProfileInfo>
                  </Col>
                </>
              )
            })}
          </Row>
        </section>
      </Container>
    </Layout>
  )
}

const ProfileInfo = styled.article`
  .template__text-body h3 {
    line-height: 1.2;
  }
`

const Article = styled.article`
  color: var(--clr-primary-6);
  padding-top: 7rem;
  text-align: center;

  @media screen and (max-width: 480px) {
    padding-top: 2.5rem;
  }

  p {
    color: var(--clr-primary-6);
  }

  figure {
    display: flex;
    flex-direction: column;
  }

  svg {
    color: var(--clr-primary-6);
  }
`

const ProfileImageCollection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;

  position: relative;
  /* height: 20rem; */

  figure {
    height: auto;
    width: 100%;
    max-width: 40%;
    margin: 0 auto;
  }
`

const SectionHeading = styled.section`
  @media screen and (max-width: 480px) {
    padding: 4rem 0 0 0;
  }

  h2.section-heading {
    text-align: center;
    color: var(--clr-primary-6);
    padding-top: 7rem;

    @media screen and (max-width: 480px) {
      padding-top: 2rem;
    }
  }
`

export default ProfileTemplate
